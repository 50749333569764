import React from "react"
import Layout from "../components/layout.js"
import Meta from "../components/meta.js"
export default function Discord() {
  return (
    <Layout>
      <Meta />
      <meta http-equiv="refresh" content="0;url=https://chat.whatsapp.com/ErUX3UsBXff0wgIS2I9wiS" />
    </Layout>
  )
}